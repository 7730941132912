export const environment = {
  production: true,

  API_URL: 'https://api.polylearn.co',
  EXAM: 'delf',
  // API_URL: 'http://127.0.0.1:8000',
  /*
  EXAM: 'linguaskill',
  */
  ENVIRONMENT: {
    graphqlUrl: 'https://graphql.polylearn.co/v1/graphql',
    nodeUrl: 'https://node.api.polylearn.co/api/'
  },
  firebase: {
    apiKey: 'AIzaSyC4pVCL5doRv5U_1o05Fmr1Kywowqx2NOA',
    authDomain: 'toeic-new-design.firebaseapp.com',
    databaseURL: 'https://toeic-new-design.firebaseio.com',
    projectId: 'toeic-new-design',
    storageBucket: 'toeic-new-design.appspot.com',
    messagingSenderId: '760238145503',
    appId: '1:760238145503:web:ca9138c1b7302e12424e6a'
  },
  data: {
    brandName: 'CLE International',
    brandNameAsCertification: false,
    brandLogoLoading: '/assets/delf/img/cle.png',
    brandLogoLoadingHeight: 100,
    brandLogo: '/assets/delf/img/cle.png',
    brandLogoSmall: '/assets/delf/img/cle.png',
    langForExercises: 'fr',
    autoplay: {
      initialValue: false,
      show: false
    }
  },
  index: {
    announcement: {
      html: "<b>L'éditeur du français langue étrangère</b>",
      link: 'https://www.cle-international.com/'
    }
  },
  navbar: [
    {
      text: 'Connexion',
      link: 'login',
      show: true,
      action: true
    },
    {
      text: 'Nous contacter',
      link: 'https://www.cle-international.com/contact-formulaire.html',
      show: true,
      action: true
    }
  ],
  levels: {
    default: 'B2',
    options: ['B1', 'B2', 'B1-JR', 'B2-JR', 'A2']
  }
};
