export const translations = {
  menu: {
    my_account: 'Mon compte',
    switch: 'Passer',
    logout: 'Déconnexion'
  },
  home: {
    my_profile: 'Mon profil',
    pseudo: 'Votre pseudo',
    timing_training: "Temps d'entrainement",
    timing_training_on_platform: "Temps d'entraînement sur la plateforme",
    level: 'Niveau :',
    switch_level: 'Changer de niveau',
    folder: 'Dossier',
    all_articles: 'Tous les articles'
  },
  profile_exam: {
    dashboard: 'Tableau de bord',
    my_dashboard: 'Mon dashboard',
    dashboard_en: 'Dashboard',
    target_level: 'Objectif',
    target_level_explaination: "C'est l'objectif que vous vous êtes fixé.",
    current_level: 'Niveau actuel',
    current_level_explaination:
      'Notre estimation se base sur les dernières informations.',
    good_answers: 'Bonnes réponses',
    good_answers_explaination:
      'Pourcentage de bonnes réponses basé sur les 100 dernières questions',
    last_test: 'Dernier test blanc',
    last_test_toeic: 'Dernier TOEIC blanc',
    last_test_delf: 'Dernier DELF blanc',
    last_test_explaination: "C'est le résultat de votre dernier test blanc.",
    start_training: 'Commencer mon entraînement',
    improve_concentration: 'Améliorer ma concentration',
    do_mock: 'Passer des examens blancs',
    exercise_stats: 'Statistiques des exercices',
    skills_stats: 'Statistiques des compétences',
    train: "M'entraîner",
    my_improvement: 'Ma progression',
    his_improvement: 'Sa progression',
    lessons_menu: {
      zero: 'Ce que je peux faire',
      one: 'Leçon récemment acquise',
      others: 'Leçons récemment acquises'
    },
    lessons_menu_worst: {
      zero: 'Bravo vous maîtrisez tous les cours !',
      one: "Plus qu'un cours, courage !",
      others: 'Focus langue : leçons à travailler'
    }
  },
  training_home: {
    personalised: 'Personnalisé',
    personalised_training: 'Entraînement personnalisé',
    random_training: "S'entraîner aléatoirement",
    estimated_score: 'Score estimé :',
    good_answers_percentage: '% de bonnes réponses',
    legend: 'Légende',
    objective_reached: 'Objectif atteint',
    objective_not_reached: 'Objectif non atteint',
    you_mastered_this_module: 'Vous maîtrisez suffisamment cette partie',
    you_didnt_mastered_this_module:
      'Entraînez-vous encore un peu pour atteindre votre objectif',
    your_objective: 'Votre objectif :',
    update_your_objective: 'Modifiez votre objectif',
    start_mock_exam: 'Commencer un test blanc',
    resume_mock_exam: 'Reprendre le test blanc',
    finish_mock_exam: 'Terminer le test blanc',
    empty_historic: "Vous n'avez pas encore terminé de test blanc"
  },
  exam_home: {
    mock_exam: 'Examen blanc',
    historic: 'Historique',
    continue: 'Reprendre',
    start: 'Commencer',
    oral_comprehension: 'Compréhension orale :',
    written_comprehension: 'Compréhension écrite :',
    oral_expression: 'Expression orale :',
    written_expression: 'Expression écrite :',
    result: 'Résultat :',
    loading_data: 'Chargement des données',
    pause: 'En pause',
    put_in_pause: 'Mettre en pause',
    resume: 'Reprendre',
    cannot_pause: "Le jour de l'examen, vous ne pourrez pas mettre en pause",
    end_mock_test: 'Terminer le test',
    back_to_historic: 'Retour à mon historique'
  },
  lessons_home: {
    focus_language: 'Focus langue',
    description:
      'Les règles à connaître pour réussir sa certification en langue française.',
    lessons: 'Cours',
    you_master_this_lesson: 'Vous maîtrisez ce cours à',
    search_placeholder: 'Rechercher un cours',
    lessons_detail: 'Détail des leçons',
    last_connexion: 'Dernière connexion :',
    estimated_time: 'Temps estimé sur la plateforme :',
    grid: {
      mastered: 'Maîtrisée',
      not_mastered: 'Non maîtrisée',
      difficulties: 'Rencontre des difficultés',
      wip: "En cours d'apprentissage (par défaut)"
    }
  },
  lesson_detail: {
    my_score: 'Mon score',
    no_score:
      'Vous avez tout retenu ? Testez-vous sur ce cours et évaluez votre niveau',
    exercise: 'Exercice',
    you_master_score: 'Je maîtrise à<b>{{score | toPercent }}%</b> ce cours.'
  },
  blog: {
    read_more_articles: "Voir plus d'articles"
  },
  training_detail: {
    choose_training_module: "Choisissez vos modules d'entraînements",
    validate: 'Valider',
    close: 'Fermer',
    help: 'Consignes',
    understood: "J'ai compris",
    no_more_showing: 'Ne plus afficher',
    my_score: 'Mon score :',
    no_train_on_lesson: "Ne plus s'entraîner sur cette leçon",
    the_instructions: 'Les consignes',
    submit_answers: 'Je valide mes réponses',
    ask_error: "Pourquoi pensez-vous qu'il y a une erreur ?",
    signal_error: 'Signalez une erreur',
    thanks_for_help: 'Merci pour votre aide !',
    register: 'S’inscrire'
  },
  correction_home: {
    exercises_to_correct: 'Exercices à corriger'
  },
  navbar: {
    training: 'Entraînement',
    exam: 'Examen',
    home: 'Accueil',
    correction: 'Correction',
    focus_langue: 'Focus langue',
    back: 'Retour',
    learners: 'Apprenants',
    trainers: 'Formateurs',
    global_groups: 'Groupes globaux',
    groups: 'Groupes',
    sub_organizations: 'Sous établissements',
    mock_exams: 'Examens blancs',
    exercises_to_correct: 'Ex. à corriger'
  },
  manager: {
    home: {
      space: 'Espace',
      sub_title: 'Bienvenue dans votre espace de gestion',
      learners_numbers: 'Nombre d’apprenants',
      trainers_numbers: 'Nombre de formateurs',
      manage: 'Gérer',
      question: 'Une question ?',
      contact_us: 'Contactez-nous',
      still_credits: 'Vous possédez {{credits}} licences'
    }
  },
  users_list: {
    add_teacher: 'Ajouter un formateur',
    add_student: 'Ajouter un apprenant',
    students_list: 'Liste des apprenants',
    teachers_list: 'Liste des formateurs',
    manager: {
      teacher_description:
        'Créer/Supprimer des formateurs. Créer des comptes formateurs est gratuit',
      student_description: 'Créer/Supprimer des apprenants'
    },
    teacher: {
      description: 'Observez la progression de vos apprenants'
    },
    table: {
      first_name: 'Prénom',
      last_name: 'Nom',
      email: 'Email',
      actions: 'Actions',
      target_score: 'Objectif',
      administrator: 'Administrateur',
      estimated_score: 'Score estimé',
      level: 'Niveau',
      timing_training: "Durée d'entraînement",
      expiration_date: 'Date d’expiration'
    },
    actions: {
      see_profile: 'Voir le profil',
      see_groups: 'Voir les groupes de l’apprenant',
      download_pdf_profile: 'Télécharger le profil (PDF)',
      send_back_login: 'Renvoyer les identifiants',
      extend_account: 'Étendre la durée du compte',
      delete_account: 'Supprimer le compte',
      switch_level: 'Changer le compte de niveau',
      remove_from_manager: 'Retirer des administrateurs',
      add_to_manager: 'Rendre administrateur'
    }
  },
  files: {
    download_csv: 'Télécharger les données (CSV)'
  },
  search: 'Rechercher',
  groups: {
    limited: 'Restreint :',
    list: 'Liste des groupes',
    add_bulk_students: "Ajout d'un lot d'apprenants",
    created_by_admin: 'Créé par un administrateur',
    delete_group: 'Supprimer le groupe',
    manager: {
      description:
        'Visualiser/Gérer vos groupes globaux. Les groupes globaux sont visibles par vos formateurs mais ils ne peuvent pas les modifier.',
      create_group: 'Créer un groupe global'
    },
    teacher: {
      description: 'Visualiser/Gérer vos groupes',
      create_group: 'Créer un groupe',
      group_name: 'Nom du groupe',
      restrain: 'Restreindre le groupe à un formateur (optionnel)',
      cancel: 'Annuler',
      create: 'Créer'
    }
  },
  institutions: {
    title: 'Liste des sous établissements',
    organization: 'Établissement',
    list: {
      add: 'Créer un sous établissement',
      logo: 'Logo',
      name: 'Nom',
      still_credits: 'Licences restantes',
      total_credits: 'Licences totales',
      used_credits: 'Licences utilisées',
      actions: 'Actions',
      organisation_edit: 'Éditer l’établissement',
      organisation_name: 'Nom de l’établissement',
      logo_url: 'URL du logo',
      slug: 'Slug pour url de connexion personnalisé',
      generate: 'Générer à partir du nom',
      close: 'Fermer',
      validate: 'Valider',
      watch_organization: 'Voir l’établissement',
      see_credits: 'Voir les licences'
    }
  },
  exercises_chart: {
    empty: 'Entraînez-vous pour obtenir vos statistiques',
    train: 'S’entraîner'
  },
  lessons_toolbar: {
    unknown: {
      zero: 'Vous maîtrisez toutes les leçons !',
      one: 'Il vous reste une leçon à maîtriser',
      others: 'Leçons non étudiées : {{unknown.length}}'
    },
    known: {
      zero: 'Aucune leçon acquise',
      one: 'Une leçon acquise',
      others: 'Leçons acquises : {{known.length}}'
    }
  },
  account: {
    password: 'Mot de passe',
    email: 'Email',
    billing: 'Facturation',
    datas: 'Données',
    password_component: {
      change_your_password: 'Changer votre mot de passe',
      old_password: 'Ancien mot de passe',
      new_password: 'Nouveau mot de passe',
      new_password2: 'Confirmer le nouveau mot de passe',
      submit: 'Valider'
    },
    email_component: {
      change_your_email: 'Changer votre email',
      new_email: 'Nouvel email',
      new_email2: 'Confirmer le nouvel email',
      submit: 'Valider'
    },
    billing_component: {
      bills: 'Factures',
      empty: "Vous n'avez pas encore de facture.",
      see_our_offers: 'Voir nos offres',
      download: 'Télécharger'
    },
    remove_account_component: {
      title: 'Suppression de mon compte',
      submit: 'Supprimer mon compte',
      remove_account_text: `Si vous souhaitez supprimer l'ensemble des données de votre compte ainsi
      que votre compte, il vous suffit de cliquer sur le bouton
<b>Supprimer mon compte</b>.<i>Aucun recours ne sera possible.</i>`
    }
  },
  dialog_lessons: {
    close: 'Fermer',
    to_train: "M'entraîner",
    score: 'score :'
  },
  part_training: {
    parts: {
      zero: 'Toutes les parties',
      one: 'Partie : ',
      others: 'Parties : '
    }
  },
  exercises: {
    question: 'Question',
    dont_know: 'Je ne sais pas répondre',
    answer_submit: 'Je valide ma réponse',
    sent_for_correction_to_teacher: 'Envoyer en correction au formateur',
    good_answer: 'Bonne réponse',
    no_answer: 'Pas de réponse.'
  },
  record_audio: {
    push_the_button:
      'Appuyez sur le bouton ci-dessous pour commencer à vous enregistrer.',
    push_the_button_to_stop:
      'Appuyez sur le bouton ci-dessous pour arrêter de vous enregistrer.',
    your_recording: 'Votre enregistrement',
    max_duration:
      "Durée d'enregistrement d'une durée maxium de {{ maxTime }} minutes.",
    only_one_try: "Vous avez un seul essai pour l'enregistrement.",
    record: 'Enregistrer',
    stop_recording: "Arrêter l'enregistrement",
    no_more_try: 'Vous ne pouvez plus enregistrer',
    cancel_recording: "Annuler l'enregistrement",
    allow_mic: "Veuillez autoriser l'accès à votre micro"
  },
  timer: {
    title: 'Temps écoulé',
    content: "Le jour de l'examen vous n'aurez pas le droit à plus de temps."
  },
  manual_correction: {
    corrected: 'Corrigés',
    to_correct: 'À corriger',
    not_corrected: 'Non corrigés',
    see_exercise: 'Voir l’exercice',
    to_correct2: 'Corriger',
    still_not_corrected: 'Pas encore corrigé',
    level: 'Niveau',
    yes: 'Oui',
    no: 'Non',
    correction_date: 'Date de correction',
    result: 'Résultat',
    date: 'Date',
    mock_exam: 'Examen blanc',
    learner: 'Apprenant',
    type: 'Production',
    actions: 'Actions',
    empty: 'Aucune donnée à afficher',
    exercise: 'Exercice',
    my_answer: 'Ma réponse',
    user_answer: "Réponse de l'apprenant",
    suggested_correction: 'Réponse suggérée',
    teacher_correction: 'Correction du formateur',
    score: 'Score :',
    not_corrected_3: "Vous n'avez pas encore été corrigé.",
    feedback: 'Feedback',
    score_2: 'Note',
    close: 'Fermer',
    validate: 'Valider',
    correction: 'Correction'
  },
  toasts: {
    offline: 'Il semblerait que vous soyez hors ligne',
    expired_token: 'Votre session a expiré',
    logout: 'Vous avez bien été déconnecté',
    incorrect_password: 'Mot de passe incorrect',
    ask_end_test: {
      title: 'Êtes-vous sûr de vouloir terminer ce test ?',
      description: 'Vous ne pourrez plus le reprendre par la suite.',
      ok: 'Terminer',
      cancel: 'Annuler'
    },
    ask_end_test_confirmation: 'Le test a bien été terminé.',
    sent_to_teacher: 'Réponse envoyée au formateur pour correction',
    modifications_updated: 'Les changements ont bien été effectué'
  },
  teacher_home: {
    title: 'Espace formateur',
    description: 'Bienvenue dans votre espace formateur',
    filter_group: 'Filtrer selon un groupe',
    remove_filter: 'Enlever le filtre',
    platform_usage: 'Utilisation de la plateforme',
    general_level: 'Niveau général',
    mean_score_lessons: 'Moyenne des leçons',
    used_account: 'Comptes utilisés',
    unused_account: 'Comptes inutilisés'
  },
  teacher_exam_home: {
    list: 'Liste',
    empty: "Aucune session n'a été planifiée",
    results: 'Résultats',
    download_csv: 'Télécharger les résultats (CSV)',
    title_delf: 'DELF blanc',
    description_delf:
      'Organisez des sessions DELF blancs et récupérer les résultats',
    action_delf: 'Organiser une nouvelle session DELF'
  },
  footer_link: {
    contact_us: 'Nous contacter',
    credits: 'Crédits',
    cgu: "Conditions d'utilisation"
  },
  logoInstitution: {
    text: 'Votre compte a été créé par'
  },
  institutions_list: {
    dialog: {
      create: 'Créer un établissement',
      edit: 'Éditer un établissement',
      created: 'L’établissement a bien été créé',
      saved: 'L’établissement a bien été édité',
      credits_usage: 'Utilisation des licences'
    }
  },
  help: {
    title: 'Aide',
    home_delf: `Sur votre page d'accueil se trouve votre tableau de bord, qui
    vous permet de consulter et de modifier votre objectif et de
    connaître votre niveau actuel. Au fur et à mesure de votre
    progression, les dernières leçons acquises apparaissent et les
    leçons qui vous posent problème vous sont proposées. Un
    diagramme vous permet de savoir quelles sont les parties de
    l'examen qui vous mettent en difficulté.<br />
    Sur cette page figurent aussi des informations relatives à
    votre compte, incluant la possibilité d'allonger votre
    abonnement, et les derniers articles sur notre blog.<br />
    Ce bouton en haut à droite vous permet de modifier votre mot
    de passe ou de vous déconnecter.<br />
    Pour modifier votre objectif, cliquez sur "Objectif", faites
    glisser le curseur jusqu'au score souhaité, puis cliquez sur
    "Valider".<br />
    Pour consulter une des leçons proposées, il vous suffit de
    cliquer dessus. La fenêtre qui apparaît vous indique également
    votre score et vous permet de vous entraîner sur cette leçon
    en particulier.<br />`,
    exam_delf: `L'onglet Examen vous permet de faire des examens blancs pour
    tester votre niveau. <br />
    <br />
    Pour connaître votre niveau, nous vous conseillons fortement de
    passer l’épreuve dans les conditions de l’examen. Prévoyez 2h
    pour la partie écrite et 1h20 pour la partie orale, sans
    interruption.<br />
    Compréhension de l’oral : 2 écoutes pour les Exercices 1 et 2, 1
    seule écoute pour l’Exercice 3<br />
    Compréhension des écrits : vous disposez de 1h<br />
    Production écrite: vous disposez de 1h<br />
    Production orale: 30 minutes de préparation, 20 minutes maximum
    d’enregistrement<br />
    <br />
    Le bouton orange et le bouton "Commencer/Reprendre" vous
    permettent de commencer un examen blanc ou de reprendre un
    examen blanc en cours. Un graphique vous permet de voir votre
    progression. Enfin, votre historique vous permet d'accéder à vos
    précédentes réponses ainsi qu'à la correction de tous les
    examens que vous avez fait.<br />
    Pendant un examen blanc, vous pouvez passer comme bon vous
    semble à l'aide des onglets en haut à gauche : lorsque vous y
    retournerez, vous reprendrez là où vous vous êtes arrêté. Vous
    pouvez interrompre un examen autant de fois que vous voulez et
    le reprendre quand vous le souhaitez. Le bouton "Autoplay" en
    haut à droite vous permet d'activer ou non le passage
    automatique à la question suivante lorsque vous avez répondu à
    toutes les questions d'une page. Quoiqu'il arrive, vous pouvez
    toujours revenir aux questions précédentes ou passer aux
    suivantes à l'aide des flèches.<br />
    Vous souhaitez vous tester sur un DELF blanc mais vous ne
    disposez pas de 3h20 d’affilée ? <br />
    Notre plateforme vous permet de commencer par n’importe quelle
    partie (Compréhension de l’oral, Compréhension des écrits,
    Production écrite, Production orale). Vous pouvez également
    réaliser l’épreuve en plusieurs fois et la reprendre à l’endroit
    où vous vous êtes arrêté(e).<br />
    Astuce: répondez toujours à la question dont vous ne connaissez
    pas la réponse, vous ne pouvez pas perdre de points.<br />`,
    training_delf: `L'onglet "Entraînement" présente votre progression par partie
    de l'examen : les pourcentages de bonnes réponses et les
    lignes rouges ou vertes au centre vous permettent de savoir où
    sont vos difficultés.<br />
    En cliquant sur « s’entraîner aléatoirement sur les parties »
    des questions de toutes les parties vous sont posées au
    hasard.<br />
    Il est aussi possible de s’entraîner par module de
    compétences.<br />
    <br />
    Une fois l'entraînement lancé, les questions démarrent.<br />
    <br />
    En haut à gauche :<br />
    <br />
    - Le bouton « toutes les parties » vous donne la possibilité
    de choisir les modules sur lesquels vous souhaitez être
    interrogé<br />
    En haut à droite :<br />
    - L’indication en bleu vous donne un aperçu global de votre
    progression<br />
    - Le bouton "consignes" vous donne la consigne de la partie
    sur laquelle vous êtes interrogé. <br />`
  }
};
